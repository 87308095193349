@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .square {
        aspect-ratio: 1 / 1;
    }

    .input-number::-webkit-inner-spin-button, .input-number::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .tooltip-white {
        @apply bg-white rounded-xl drop-shadow-black-100 text-white font-gilroy-400 text-black opacity-100
        max-w-[80vw]
    }

    .input-number[type=number] {
        -moz-appearance: textfield;
    }

    .transition-full {
        transition: all 0.5s linear;
    }

    .stroke-path-white path {
        @apply stroke-white
    }

    .stroke-path-grey-100 path {
        @apply stroke-grey-100
    }

    .stroke-path-grey path {
        @apply stroke-white-200
    }

    .stroke-path-green path {
        @apply stroke-green-100
    }

    .btn {
        @apply flex justify-center rounded-full transition-full font-gilroy-600 gap-2;
    }

    /*btn-green*/
    .btn-green {
        @apply bg-green-rgb-100 text-white
    }

    /*btn-turquoise*/
    .btn-turquoise {
        @apply bg-turquoise-100 text-white
    }

    .btn-turquoise:active {
        @apply text-blue-100 bg-white;
    }

    .btn-turquoise:disabled {
        @apply text-blue-400 bg-white-100 border-2 border-blue-400;
    }

    .btn-turquoise:hover:not(:disabled):not(:active) {
        @apply text-blue-100 bg-gradient-turquoise-200 shadow-turquoise-100;
    }

    /*btn-turquoise-outline*/
    .btn-turquoise-outline {
        @apply bg-white text-turquoise-100 border-2 border-turquoise-100
    }

    .btn-turquoise-outline:active {
        @apply text-blue-100 bg-white border-white;
    }

    .btn-turquoise-outline:disabled {
        @apply text-blue-400 bg-white-100 border-2 border-blue-400;
    }

    .btn-turquoise-outline:hover:not(:disabled):not(:active) {
        @apply text-blue-100 bg-gradient-turquoise-200 shadow-turquoise-100;
    }

    /*btn-turquoise-light*/
    .btn-turquoise-light {
        @apply bg-gradient-turquoise-400 text-blue-100 shadow-black-200
    }

    .btn-turquoise-light:active {
        @apply text-blue-100 bg-white;
    }

    .btn-turquoise-light:disabled {
        @apply text-blue-400 bg-white-100 border-2 border-blue-400;
    }

    .btn-turquoise-light:hover:not(:disabled):not(:active) {
        @apply text-blue-100 bg-gradient-turquoise-200 shadow-turquoise-100;
    }

    /*btn-grey*/
    .btn-grey {
        @apply bg-grey-100 text-white
    }

    .btn-grey svg {
        @apply fill-white
    }

    .btn-grey:active {
        @apply bg-blue-100;
    }

    /*btn-icon*/
    .btn-turquoise-icon {
        @apply bg-white text-white
    }

    .btn-turquoise-icon svg {
        @apply fill-turquoise-100
    }

    .font-gilroy-400 {
        font-family: 'Gilroy Regular', sans-serif;
        font-weight: 400;
    }

    .font-gilroy-500 {
        font-family: 'Gilroy Medium', sans-serif;
        font-weight: 500;
    }

    .font-gilroy-600 {
        font-family: 'Gilroy SemiBold', sans-serif;
        font-weight: 600;
    }

    .font-gilroy-700 {
        font-family: 'Gilroy Bold', sans-serif;
        font-weight: 700;
    }

    .font-gilroy-800 {
        font-family: 'Gilroy ExtraBold', sans-serif;
        font-weight: 800;
    }

    .font-gilroy-900 {
        font-family: 'Gilroy Black', sans-serif;
        font-weight: 900;
    }
}


@font-face {
    font-family: 'Gilroy Regular';
    src: url('./assets/fonts/Gilroy-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy Medium';
    src: url('./assets/fonts/Gilroy-Medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy SemiBold';
    src: url('./assets/fonts/Gilroy-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy Bold';
    src: url('./assets/fonts/Gilroy-Bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy ExtraBold';
    src: url('./assets/fonts/Gilroy-Extrabold.ttf') format('truetype');
    font-weight: 800;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy Black';
    src: url('./assets/fonts/Gilroy-Black.ttf') format('truetype');
    font-weight: 900;
    font-display: swap;
}

html,
body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

*::-webkit-scrollbar,
html *::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}

*::-webkit-scrollbar-track,
html *::-webkit-scrollbar-track {
    background: rgba(130, 152, 173, 0.3);
}

*::-webkit-scrollbar-thumb,
html *::-webkit-scrollbar-thumb {
    background-color: rgba(0, 202, 215, 0.5);
    border: 6px solid rgba(0, 202, 215, 0.5);
    border-radius: 10px;
}

a {
    color: inherit;
    text-decoration: none;
}

input:focus {
    outline: none;
}

textarea:focus {
    outline: none;
}

#root {
    width: 100%;
    height: 100%;
}

* {
    box-sizing: border-box;
}
